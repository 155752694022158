var exports = {};
function _extends() {
  return exports = _extends = Object.assign ? Object.assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, exports.__esModule = true, exports["default"] = exports, _extends.apply(null, arguments);
}
exports = _extends, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;